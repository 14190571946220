import React, { useRef, useState, useEffect } from "react";
import { DataFeed } from "./datafeed";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import {fetchSymbols, IApiSymbol } from "./services";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ChartView from "./components/ChartView";
import ApexChart from "./components/ApexChart";

const icon_url = "https://static.okex.com/cdn/oksupport/asset/currency/icon/";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menu: {
    marginRight: theme.spacing(2),
  },
}));
function App() {
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState<IApiSymbol[]>([]);
  const [info, setInfo] = useState<IApiSymbol>();

  

  useEffect(() => {
    if (list) {
        setInfo(list[0])
      }
  },[list])
  




  const onClick = () => {
    setVisible(!visible);
  };

  const onSelected = (e: IApiSymbol) => {
   

    console.log({"onSelected":e})
    setVisible(false)
    setInfo(e);
  };



  useEffect(() => {
    initSymbol()
  }, [])
  

  const initSymbol = async() => {
    const res = await fetchSymbols();
    if (!res) return;
    const obj: Record<string, IApiSymbol> = {};
    for (let i = 0; i < res.length; i++) {
        const item = res[i];
        obj[item["stock"]] = item;
    }
    const arr = Object.keys(obj).sort();
    const newList = arr.map((k) => obj[k]);
    setList(newList)
  }

 
  
  const styles = useStyles();
  const title = info
    ? `${info["stock"].toLocaleUpperCase()}/${info[
        "currency"
      ].toLocaleUpperCase()}`
    : "";

  return (
    <div className="app">

      <div className={styles.root}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              className={styles.menu}
              onClick={onClick}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>


      <SwipeableDrawer open={visible} onOpen={() => {}} onClose={onClick}>
        <List dense={true}>
          {list.map((e, i) => {
            return (
              <ListItem
                key={e.stock}
                button={true}
                selected={e.stock === info?.stock}
              >
             
                <ListItemText
                  style={{
                    color: e.stock === info?.stock ? "red" : "inherit",
                  }}
                  primary={`${e["stock"].toLocaleUpperCase()}/${e[
                    "currency"
                  ].toLocaleUpperCase()}`}
                  onClick={() => onSelected(e)}
                />
              </ListItem>
            );
          })}
        </List>


      </SwipeableDrawer>

              {info?<ApexChart info={ info} />:null}

      {/* <ChartView onList={(_list: any) => { setList(_list) }} info={info} /> */}
    </div>
  );
}

export default React.memo(App);

import axios from 'axios';
import { IgrFinancialChart, IgrFinancialChartModule } from 'igniteui-react-charts';
import { useEffect, useState } from 'react';
import config from "../../config"

IgrFinancialChartModule.register();

const ApexChart = ({ info }: any) => {

    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(false)
    useEffect(() => {
        if (info) {
            getHistoricalData()
            if (info.code=== "US") {
                startWebSocket()
            }
        }
    }, [info])


    const startWebSocket = () => {
        const ws = new WebSocket(`wss://ws.eodhistoricaldata.com/ws/us?api_token=${config.API_KEY}`);

        const apiCall = {
            "action": "subscribe",
            "symbols": info.stock
        }

        
          ws.onopen = (event) => {
            ws.send(JSON.stringify(apiCall));
          };
        
          ws.onmessage = function (event) {
              const json = JSON.parse(event.data);
              console.log({"onmessage":json})
            try {
              if ((json.event = "data")) {
                // setBids(json.data.bids.slice(0, 5));
              }
            } catch (err) {
              console.log(err);
            }
          };

    }

    const getHistoricalData = async () => {
        setLoading(true)
        try {
            const url = `https://eodhistoricaldata.com/api/intraday/${info.stock}.${info.code}?api_token=${config.API_KEY}&interval=5m&fmt=json`
            console.log({ url })
            const resp = await axios.get(url);
            const data = resp.data;

            const finalData = data.map((item: any) => {
            

                return {
                    Open: item.open,
                    Close: item.close,
                    Low: item.low,
                    Volume: item.volume,
                    Date: new Date(item.timestamp),
                    High:item.high
                    
                }


            })

            setData(finalData)
        } catch (err) {
            console.error("dsdsdsds", err)
        }

        setLoading(false)
    }

   
    return (
        <div className="container sample" style={{
            height: "50%",



        }}>
            <div className="container" style={{
                height: "90vh",
                // height: "50%",

                background:"#131722"


            }}>
                {isLoading ? "Loading..." : <IgrFinancialChart
                    width="100%"
                    height="100%"
                    // isToolbarVisible={true}
                    chartType="Candle"
                    chartTitle={info.name}
                    zoomSliderType="Candle"
                    volumeType="Area"
                    overlayBrushes="rgba(5, 138, 0, 0.17)"
                    overlayOutlines="rgba(5, 138, 0, 0.4)"
                    overlayThickness={1}
                   
                    dataSource={data} />}

            </div>
        </div>
    )
}

export default ApexChart